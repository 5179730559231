import {alertController} from "@ionic/vue";
import numeral from 'numeral';

export async function info(title, msg) {
    const alert = await alertController.create({
        header: title,
        message: msg,
        buttons: ['Close']
    });

    await alert.present();
}

export function fmtMoney(val) {
    return numeral(val).format('0,0.00');
}