<template>
  <ion-content>
    <div class="ion-padding">

      <ion-radio-group v-model="data">
        <ion-list-header>
          <ion-label color="success">
            {{ title }}
          </ion-label>
        </ion-list-header>

        <ion-item v-for="(val, key) in items" :key="key">
          <ion-label>{{ val }}</ion-label>
          <ion-radio :value="key"></ion-radio>
        </ion-item>
      </ion-radio-group>

      <div class="ion-margin">
        <ion-button color="success" :disabled="!valueSelected" @click="save()">
          Save
        </ion-button>
        <ion-button color="danger" @click="cancel()">Cancel</ion-button>
      </div>

    </div>
  </ion-content>
</template>

<script>
import {
  IonItem,
  IonItemDivider,
  IonLabel,
  IonText,
  IonNote,
  IonInput,
  IonButton,
  IonIcon,
  IonRadio,
  IonRadioGroup,
  IonListHeader,
  IonContent,

  modalController,
} from '@ionic/vue';

export default {
  name: "Filter",
  components: {
    IonItem,
    IonItemDivider,
    IonLabel,
    IonText,
    IonNote,
    IonInput,
    IonButton,
    IonIcon,
    IonRadio,
    IonRadioGroup,
    IonListHeader,
    IonContent,
  },

  props: {
    input: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    items: {
      type: Array,
    }
  },

  computed: {
    valueSelected() {
      return this.data.length > 0;
    }
  },

  data() {
    let input = this.input;
    if(input !== undefined)
      input = `${input}`;

    return {
      data: input,
    }
  },

  methods: {
    save() {
      if (this.data) {
        modalController.dismiss(parseInt(this.data));
      }
    },

    cancel() {
      modalController.dismiss();
    }
  }
}
</script>