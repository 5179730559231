<template>
  <ion-grid class="table">
    <ion-row>
      <ion-col>
        <ion-card>
          <ion-card-header>
            <ion-card-title>Summary</ion-card-title>
          </ion-card-header>

          <ion-card-content class="summary-container">
            <div class="summary">
              There are
              <span class="emphasize">{{ numSecurities }}</span> different
              securities in this portfolio.<br/>

              Your monthly income would be roughly
              <span class="emphasize">{{currency}} {{ fmtMoney(totalIncome) }}</span>.<br/>

              The portfolio would cost you
              <span class="emphasize">{{currency}} {{ fmtMoney(total_value) }}</span> to
              purchase.
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row class="header">

      <ion-col class="ion-hide-md-down">
        Security
      </ion-col>

      <ion-col size-lg="3">
        Ticker
      </ion-col>

      <ion-col class="ion-hide-md-down" size-lg="2">
        Type
      </ion-col>

      <ion-col @click="sort('price')" class="clickable" size-lg="3">
        Price ({{ currency }})
        <ion-icon v-if="isSortedPriceDesc" :icon="iconDown"></ion-icon>
        <ion-icon v-else-if="isSortedPriceAsc" :icon="iconUp"></ion-icon>
      </ion-col>

      <ion-col @click="sort('count')" class="clickable" size-lg="2">
        Shares
        <ion-icon v-if="isSortedCountDesc" :icon="iconDown"></ion-icon>
        <ion-icon v-else-if="isSortedCountAsc" :icon="iconUp"></ion-icon>
      </ion-col>

      <ion-col @click="sort('value')" class="clickable">
        Total value
        <ion-icon v-if="isSortedValueDesc" :icon="iconDown"></ion-icon>
        <ion-icon v-else-if="isSortedValueAsc" :icon="iconUp"></ion-icon>
      </ion-col>

      <ion-col @click="sort('percent')" class="clickable ion-hide-md-down"
               size-lg="2">
        %
        <ion-icon v-if="isSortedPercentDesc" :icon="iconDown"></ion-icon>
        <ion-icon v-else-if="isSortedPercentAsc" :icon="iconUp"></ion-icon>
      </ion-col>

      <ion-col @click="sort('dpm')" class="clickable">
        Monthly income
        <ion-icon v-if="isSortedDpmDesc" :icon="iconDown"></ion-icon>
        <ion-icon v-else-if="isSortedDpmAsc" :icon="iconUp"></ion-icon>
      </ion-col>

    </ion-row>

    <ion-row v-for="(item, idx) in items"
             @click="rowClicked(idx)"
             :key="idx"
             :class="['data-row', {pinned: item.pinned}]">
      <ion-col class="ion-hide-md-down" v-html="item.name"></ion-col>

      <ion-col size-lg="3">
        <span :title="item.name" class="ticker">
          <a :href="`https://finance.yahoo.com/quote/${item.ticker}`"
             target="_blank">{{ item.ticker }}</a>
        </span>
      </ion-col>

      <ion-col class="ion-hide-md-down" size-lg="2">
        {{ item.typ }}
      </ion-col>

      <ion-col size-lg="3">
        <span :title="price_title(item)">
          {{ fmtMoney(item.price) }}
        </span>
      </ion-col>

      <ion-col size-lg="2">
        {{ item.count }}
      </ion-col>

      <ion-col>
        {{ fmtMoney(item.value) }}
      </ion-col>

      <ion-col class="ion-hide-md-down" size-lg="2">
        {{ fmtFloat(item.percent) }}
      </ion-col>

      <ion-col>
        <section v-if="selectedRow === idx">
          <ion-button v-if="isPinned(idx)" size="small"
                      @click="unpin(item.id)" color="success">
            Unpin
          </ion-button>
          <ion-button v-else size="small" color="success"
                      @click="pin(item.id)">
            Pin
          </ion-button>

          <ion-button size="small" color="danger"
                      @click="drop(item.id)">
            Drop
          </ion-button>

          <ion-button size="small" color="danger"
                      fill="clear"
                      title="Close"
                      @click.stop="resetSelectedRow">
            <ion-icon :icon="iconClose"></ion-icon>
          </ion-button>
        </section>

        <span v-else>
          {{ fmtMoney(item.dpm) }}
        </span>
      </ion-col>

    </ion-row>

  </ion-grid>

</template>

<script>

import FmtMixin from '@/mixins/fmt';

import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/vue';

import {
  arrowDropdown as iconDown,
  arrowDropup as iconUp,
  closeCircleOutline as iconClose,
} from 'ionicons/icons';
import {currencies} from "@/data";

export default {
  name: "ResultTable",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
  },

  props: ['solutions', 'currency'],
  mixins: [FmtMixin],
  emits: ['pinned', 'unpinned', 'dropped'],

  data() {
    return {
      items: [...this.solutions],
      sortedByField: null,
      sortedDirectionDesc: true,
      selectedRow: -1,

      iconDown,
      iconUp,
      iconClose,
    }
  },

  mounted() {
    this.sort(this.sortedByField ? this.sortedByField : 'value', true);
  },

  watch: {
    solutions: {
      handler(val) {
        this.items = [...val];
        this.sort(this.sortedByField ? this.sortedByField : 'value', true);
      },
      deep: true
    },
  },

  methods: {
    price_title(item) {
      return `${this.fmtFloat(item.orig_price) } ${this.cur(item.currency)}`;
    },

    cur(code) {
      return currencies[code];
    },

    rowClicked(idx) {
      if(this.selectedRow === idx)
        this.selectedRow = -1;
      else
        this.selectedRow = idx;
    },

    isPinned(idx) {
      return this.items[idx].pinned;
    },

    sort(field, skipToggle) {
      if (this.sortedByField === field && !skipToggle) {
        this.sortedDirectionDesc = !this.sortedDirectionDesc;
      } else {
        this.sortedByField = field;
        this.sortedDirectionDesc = true;
      }

      this.items.sort((a, b) => {
        if (this.sortedDirectionDesc) {
          return b[field] - a[field]
        } else {
          return a[field] - b[field]
        }
      });
    },

    pin(id) {
      this.$emit('pinned', id);
    },

    unpin(id) {
      this.$emit('unpinned', id);
    },

    drop(id) {
      this.$emit('dropped', id);
    },

    resetSelectedRow() {
      this.selectedRow = -1;
    }
  },

  computed: {
    isSortedValueAsc() {
      return this.sortedByField === 'value' && !this.sortedDirectionDesc;
    },

    isSortedValueDesc() {
      return this.sortedByField === 'value' && this.sortedDirectionDesc;
    },

    isSortedPriceAsc() {
      return this.sortedByField === 'price' && !this.sortedDirectionDesc;
    },

    isSortedPriceDesc() {
      return this.sortedByField === 'price' && this.sortedDirectionDesc;
    },

    isSortedDpmAsc() {
      return this.sortedByField === 'dpm' && !this.sortedDirectionDesc;
    },

    isSortedDpmDesc() {
      return this.sortedByField === 'dpm' && this.sortedDirectionDesc;
    },

    isSortedCountAsc() {
      return this.sortedByField === 'count' && !this.sortedDirectionDesc;
    },

    isSortedCountDesc() {
      return this.sortedByField === 'count' && this.sortedDirectionDesc;
    },

    isSortedPercentAsc() {
      return this.sortedByField === 'percent' && !this.sortedDirectionDesc;
    },

    isSortedPercentDesc() {
      return this.sortedByField === 'percent' && this.sortedDirectionDesc;
    },

    total_value() {
      return this.items.map(i => i.value).reduce((a, b) => a + b, 0)
    },

    totalIncome() {
      return this.items.map(i => i.dpm).reduce((a, b) => a + b, 0)
    },

    numSecurities() {
      return this.items.length;
    }
  }
}
</script>

<style scoped>
.header {
  font-weight: 500;
  border-bottom: 2px solid #000;
}

.data-row {
  border-bottom: 1px solid #000;
  cursor: pointer;
}

.data-row:nth-of-type(odd) {
  background: #EEEEEE;
}

.pinned {
  background: #c8da8c !important;
}

.ticker {
  cursor: help;
}

.ticker a {
  color: black;
}

.clickable {
  cursor: pointer;
}

.emphasize {
  font-weight: bold;
  color: var(--ion-color-primary);
}

.summary-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: medium;
}

.summary {
  text-align: left;
}

.table {
  --ion-grid-columns: 24;
}

</style>