<template>
  <ion-footer>
    <ion-toolbar>
      <span class="left">
        <p class="p"><small>Version: {{VERSION}}</small></p>
        <p class="p" v-if="hasInfo">
          <small>
            Database: {{info.stock}} stocks,
            {{info.etf}} ETFs,
            {{info.fund}} funds,
            {{info.trust}} trusts.
          </small>
        </p>
        <p class="p">
        <small>
          All stock quotes on this website should be considered as having a 24-hour delay.
        </small>
      </p>
      </span>

      <span class="right" slot="end">
        <p class="p"><small>Copyright &copy; 2021 Max Kuznetsov</small></p>
        <p class="p">
          <ion-icon :icon="iconEmail"></ion-icon>
          <a class="link" href="mailto:wealthpic@syhpoon.ca">
            <small>wealthpic@syhpoon.ca</small>
          </a>
        </p>
        <p class="p">
          <ion-icon :icon="iconTwitter"></ion-icon>
          <a class="link" href="https://twitter.com/wealthpic">
            <small>@wealthpic</small>
          </a>
        </p>
        </span>
    </ion-toolbar>
  </ion-footer>
</template>

<script>

import {VERSION} from '@/version';

import {
  mail as iconEmail,
  logoTwitter as iconTwitter,
} from 'ionicons/icons';

import {
  IonFooter,
  IonToolbar,
  IonIcon,
} from '@ionic/vue';

export default {
  name: 'Footer',
  props: ['info'],
  data() {
    return {
      iconEmail,
      iconTwitter,
      VERSION,
    }
  },

  computed: {
    hasInfo() {
      return this.info !== undefined;
    }
  },

  components: {
    IonFooter,
    IonToolbar,
    IonIcon,
  },
};
</script>

<style scoped>

.left {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.right {
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.link {
  margin-left: 3px;
}

.p {
  margin-block-start: 2px;
  margin-block-end: 2px;
}

</style>
