<template>
  <ion-page>

    <ion-content :fullscreen="true">

      <div class="wrapper">
        <Header></Header>

        <div class="content" id="container">
          <ion-grid>
            <ion-row>
              <ion-grid>
                <ion-row class="ion-align-items-start">

                  <ion-col size="12" size-lg="3">
                    <!-- Selectors -->
                    <ion-list>
                      <ion-list-header>
                        <ion-text color="primary">Primary Objectives</ion-text>
                      </ion-list-header>

                      <ion-item color="primary" button
                                :disabled="!canSelectDpm"
                                @click="addObjDpm()">
                        <ion-icon :icon="iconDpm"
                                  slot="start"></ion-icon>
                        Fixed monthly income
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="dpmInfo"></ion-icon>
                      </ion-item>

                      <ion-item color="primary" button
                                :disabled="!canSelectFixedCost"
                                @click="addObjFixedCost()">
                        <ion-icon :icon="iconFixedCost"
                                  slot="start"></ion-icon>
                        Fixed portfolio cost
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="fixedCostInfo"></ion-icon>
                      </ion-item>

                    </ion-list>

                    <ion-list>
                      <ion-list-header>
                        <ion-text color="secondary">Secondary Objectives
                        </ion-text>
                      </ion-list-header>

                      <ion-item color="secondary" button
                                :disabled="!canSelectMinimizeCost"
                                @click="addObjMinimizeCost()">
                        <ion-icon :icon="iconMinimizeCost"
                                  slot="start"></ion-icon>
                        Minimize portfolio cost
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="minimizePortfolioCostInfo"></ion-icon>
                      </ion-item>

                      <ion-item color="secondary" button
                                :disabled="!canSelectMaximizeDpm"
                                @click="addObjMaximizeDpm()">
                        <ion-icon :icon="iconMaximizeDpm"
                                  slot="start"></ion-icon>
                        Maximize monthly income
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="maximizeDpmInfo"></ion-icon>
                      </ion-item>
                    </ion-list>

                    <!-- Constraints -->
                    <ion-list>
                      <ion-list-header>
                        <ion-text color="warning">Constraints</ion-text>
                      </ion-list-header>

                      <ion-item color="warning" button
                                @click="addConstraintIssuers()">
                        <ion-icon :icon="iconConstraints"
                                  slot="start"></ion-icon>
                        Issuers
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="constraintsIssuersInfo"></ion-icon>
                      </ion-item>

                      <ion-item color="warning" button
                                @click="addConstraintPin()">
                        <ion-icon :icon="iconPin" slot="start"></ion-icon>
                        Pin
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="constraintsPinInfo"></ion-icon>
                      </ion-item>
                    </ion-list>

                    <!-- Filters -->
                    <ion-list>
                      <ion-list-header>
                        <ion-text color="success">Filters</ion-text>
                      </ion-list-header>

                      <ion-item color="success" button
                                @click="addFilterCountry()">
                        <ion-icon :icon="iconCountry" slot="start"></ion-icon>
                        Country
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="countryFilterInfo"></ion-icon>
                      </ion-item>

                      <ion-item color="success" button
                                @click="addFilterExchange()">
                        <ion-icon :icon="iconExchange" slot="start"></ion-icon>
                        Exchange
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="exchangeFilterInfo"></ion-icon>
                      </ion-item>

                      <ion-item color="success" button
                                @click="addFilterIndustry()">
                        <ion-icon :icon="iconIndustry" slot="start"></ion-icon>
                        Industry
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="industryFilterInfo"></ion-icon>
                      </ion-item>

                      <ion-item color="success" button
                                @click="addFilterSector()">
                        <ion-icon :icon="iconSector" slot="start"></ion-icon>
                        Sector
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="sectorFilterInfo"></ion-icon>
                      </ion-item>

                      <ion-item color="success" button
                                @click="addFilterType()">
                        <ion-icon :icon="iconType" slot="start"></ion-icon>
                        Type
                        <ion-icon :icon="iconInfo" slot="end"
                                  @click.stop="typeFilterInfo"></ion-icon>
                      </ion-item>

                    </ion-list>

                  </ion-col>

                  <!-- Main space -->
                  <ion-col>

                    <ion-grid>
                      <ion-row>
                        <ion-col>
                          <ion-card class="ion-padding">
                            <template v-if="hasItems">

                              <!-- Pinned currency item -->
                              <ion-chip color="dark">
                                <ion-icon :icon="iconCurrency"></ion-icon>
                                <ion-label>
                                  Currency: {{ currency_str }}
                                </ion-label>

                                <ion-icon :icon="iconEdit"
                                          class="edit"
                                          title="Edit"
                                          @click="editCurrency()">
                                </ion-icon>

                              </ion-chip>

                              <ion-chip v-for="(item, idx) in items"
                                        :color="item.color"
                                        :key="idx">
                                <ion-icon :icon="item.icon"></ion-icon>
                                <ion-label>
                                  {{ item.title }} {{ item.attributes }}
                                </ion-label>

                                <ion-icon v-if="!item.no_edit"
                                          :icon="iconEdit"
                                          class="edit"
                                          title="Edit"
                                          @click="editItem(idx)">
                                </ion-icon>

                                <ion-icon :icon="iconClose"
                                          color="danger"
                                          title="Remove"
                                          @click="removeItem(idx)">
                                </ion-icon>

                              </ion-chip>
                            </template>

                            <template v-else>
                              <h3>Using WealthPIC is simple!</h3>

                              <ol class="ion-text-start howto">
                                <li>
                                  Select one
                                  <ion-text color="primary"> primary objective
                                  </ion-text>
                                </li>
                                <li>
                                  Select your currency (optional)
                                </li>
                                <li>
                                  Select some
                                  <ion-text color="secondary"> secondary
                                    objectives
                                  </ion-text>
                                  (optional)
                                </li>
                                <li>
                                  Select some
                                  <ion-text color="warning">constraints
                                  </ion-text>
                                  (optional)
                                </li>
                                <li>
                                  Select some
                                  <ion-text color="success">filters</ion-text>
                                  (optional)
                                </li>
                                <li>
                                  Click
                                  <ion-text color="danger">
                                    Calculate
                                  </ion-text>
                                </li>
                                <li>
                                  Pin securities you like in the resulting
                                  portfolio, edit them as needed or
                                  add more constrains and filters and repeat.
                                  (optional)
                                </li>
                              </ol>
                            </template>

                            <ion-item-divider
                                class="ion-margin-vertical"></ion-item-divider>

                            <ion-button color="danger"
                                        @click="calculate()"
                                        :disabled="calculationDisabled">

                              <ion-spinner v-if="computing"
                                           name="circles"></ion-spinner>

                              <ion-icon v-else :icon="iconCalculate" slot="start">
                              </ion-icon>

                              Calculate
                            </ion-button>

                            <ion-button @click="reset()">
                              <ion-icon :icon="iconReset"
                                        slot="start"></ion-icon>
                              Reset
                            </ion-button>

                            <br/>

                            <small>
                              Please review our
                              <router-link to="/disclaimer">disclaimer
                              </router-link>
                              before using this tool.
                            </small>
                          </ion-card>
                        </ion-col>
                      </ion-row>

                      <ion-row>
                        <ion-col>

                          <div class="tabs" v-if="hasSolutions">
                            <div v-for="(p, id) in portfolios" :key="id"
                                 @click="portfolioChange(id)"
                                 v-bind:class="['tab-title',
                                {'tab-selected': id === visiblePortfolio}]">
                              <ion-label>
                                Portfolio #{{ id + 1 }}: {{ fmt(p.amount) }}
                              </ion-label>

                              <ion-icon :icon="iconDelete" color="danger"
                                        class="remove-portfolio"
                                        @click.stop.prevent="removePortfolio(id)"></ion-icon>
                            </div>
                          </div>

                          <template v-if="infeasible">
                            <h5>
                              Wealthpic couldn't find a feasible solution given
                              your constraints. <br/>
                              Try clicking Calculate
                              a couple more times or tweak the optimization
                              criteria. <br />
                              In particular, try doubling the <strong>iterations</strong>
                              value.
                            </h5>
                          </template>

                          <template v-if="hasSolutions">
                            <div v-for="(p, id) in portfolios" :key="id">
                              <ResultTable v-if="visiblePortfolio === id"
                                           @pinned="onPinned"
                                           @unpinned="onUnpinned"
                                           @dropped="onDropped"
                                           :currency="currency_str"
                                           :solutions="p.solutions"></ResultTable>
                            </div>
                          </template>

                        </ion-col>
                      </ion-row>

                    </ion-grid>
                  </ion-col>

                </ion-row>
              </ion-grid>

            </ion-row>
          </ion-grid>
        </div>

        <Footer :info="dbinfo" class="footer"></Footer>
      </div>
    </ion-content>

  </ion-page>
</template>

<script>

import {
  analytics as iconMaxDividends,
  stats as iconDpm,
  wallet as iconFixedCost,
  cash as iconCurrency,
  arrowDown as iconMinimizeCost,
  arrowUp as iconMaximizeDpm,
  business as iconIndustry,
  pie as iconSector,
  informationCircleOutline as iconInfo,
  map as iconCountry,
  cog as iconCalculate,
  refresh as iconReset,
  closeCircleOutline as iconClose,
  create as iconEdit,
  codeWorking as iconConstraints,
  attach as iconPin,
  filing as iconExchange,
  pricetags as iconType,
  trash as iconDelete,
} from 'ionicons/icons';

import {
  IonAlert,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonList,
  IonListHeader,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonItemDivider,
  IonButton,
  IonItem,
  IonChip,
  IonLabel,
  IonModal,
  IonSpinner,
  IonFooter,

  alertController,
  modalController,
} from '@ionic/vue';

import ObjDpm from './ObjDpm.vue';
import ObjFixedCost from './ObjFixedCost.vue';
import Filter from './Filter.vue';
import ConstraintIssuers from './ConstraintIssuers.vue';
import ConstraintPin from './ConstraintPin.vue';
import ResultTable from "./ResultTable.vue";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import COUNTRY_CODES from "@/countries";

import {v4 as uuid} from 'uuid';

import numeral from 'numeral';
import {info, fmtMoney} from "@/lib";
import {
  countries,
  currencies,
  currencies_rev,
  exchanges,
  industries,
  sectors,
  types,
  types_rev
} from "@/data";

const {all_securities} = wasm_bindgen;

const typeObjective = "objective";
const typeConstraint = "constraint";
const typeFilter = "filter";

const obj_dpm = "dpm";
const obj_fixed_cost = "fixed-cost";
const obj_minimize_cost = "minimize-cost";
const obj_maximize_dpm = "maximize-dpm";
const constraintIssuers = "issuers";
const constraintPin = "pin";
const filterCountry = "country";
const filterExchange = "exchange";
const filterIndustry = "industry";
const filterSector = "sector";
const filterType = "type";

async function showObjDpm(currency, value, iterations, toleration) {
  return new Promise((resolve) => {
    (async () => {

      const modal = await modalController
          .create({
            component: ObjDpm,
            componentProps: {
              value,
              iterations,
              toleration,
              currency,
            }
          });

      modal.onDidDismiss().then(data => {
        if (data.data !== undefined) {
          const d = data.data;

          resolve({
            color: "primary",
            type: typeObjective,
            icon: iconDpm,
            kind: obj_dpm,
            obj_primary: true,
            title: `Fixed income: ${d.value}`,
            attributes: `iterations=${d.iterations} toleration=${d.toleration}`,
            value: d.value,
            iterations: d.iterations,
            toleration: d.toleration,
          })
        } else {
          resolve(null);
        }
      })

      await modal.present();
    })();
  });
}

async function showObjFixedCost(value, iterations, toleration) {
  return new Promise((resolve) => {
    (async () => {

      const modal = await modalController
          .create({
            component: ObjFixedCost,
            componentProps: {
              value,
              iterations,
              toleration,
            }
          });

      modal.onDidDismiss().then(data => {
        if (data.data !== undefined) {
          const d = data.data;

          resolve({
            color: "primary",
            type: typeObjective,
            icon: iconFixedCost,
            kind: obj_fixed_cost,
            obj_primary: true,
            title: `Fixed cost: ${d.value}`,
            attributes: `iterations=${d.iterations} toleration=${d.toleration}`,
            value: d.value,
            iterations: d.iterations,
            toleration: d.toleration,
          })
        } else {
          resolve(null);
        }
      })

      await modal.present();
    })();
  });
}

async function showConstraintIssuers(min, max) {
  return new Promise((resolve) => {
    (async () => {

      const modal = await modalController
          .create({
            component: ConstraintIssuers,
            componentProps: {min, max}
          });

      modal.onDidDismiss().then(data => {
        if (data.data !== undefined) {
          const d = data.data;
          let title = "Issuers:";

          if (d.min !== undefined) {
            title += ` min=${d.min}`;
          }

          if (d.max !== undefined) {
            title += ` max=${d.max}`;
          }

          resolve({
            color: "warning",
            type: typeConstraint,
            icon: iconConstraints,
            kind: constraintIssuers,
            title: title,
            min: d.min,
            max: d.max,
          })
        } else {
          resolve(null);
        }
      })

      await modal.present();
    })();
  });
}

async function showConstraintPin(registry, ticker, name, typ, price, dpm, count,
                                 id, currency) {
  return new Promise((resolve) => {
    (async () => {

      const modal = await modalController
          .create({
            component: ConstraintPin,
            componentProps: {ticker, name, typ, price, dpm, count, registry, currency}
          });

      modal.onDidDismiss().then(data => {
        if (data.data !== undefined) {
          const d = data.data;
          let title = `Pin: ${d.ticker} shares=${d.count} price=${fmtMoney(
              d.price)} ${currencies_rev[d.currency]}`;

          if (!id) {
            id = uuid();
          }

          resolve({
            color: "warning",
            type: typeConstraint,
            icon: iconPin,
            kind: constraintPin,
            title: title,
            ticker: d.ticker,
            name: d.name,
            typ: d.typ,
            price: d.price,
            dpm: d.dpm,
            count: d.count,
            id: id,
            currency: d.currency,
          })
        } else {
          resolve(null);
        }
      })

      await modal.present();
    })();
  });
}

async function showFilterCountry(value) {
  let codes = {};
  for (const k in countries) {
    codes[k] = COUNTRY_CODES[countries[k]];
  }

  return new Promise((resolve) => {
    (async () => {

      const modal = await modalController
          .create({
            component: Filter,
            componentProps: {
              input: value,
              title: 'Include securities from the selected country',
              items: codes,
            }
          });

      modal.onDidDismiss().then(data => {
        if (data.data !== undefined) {
          resolve({
            color: "success",
            type: typeFilter,
            icon: iconCountry,
            kind: filterCountry,
            title: `Country: ${codes[data.data]}`,
            country: data.data,
          });
        } else {
          resolve(null);
        }
      })

      await modal.present();
    })();
  });
}

async function showFilterExchange(value) {
  return new Promise((resolve) => {
    (async () => {

      const modal = await modalController
          .create({
            component: Filter,
            componentProps: {
              input: value,
              title: 'Include securities traded on a selected exchange',
              items: exchanges,
            }
          });

      modal.onDidDismiss().then(data => {
        if (data.data !== undefined) {
          resolve({
            color: "success",
            type: typeFilter,
            icon: iconExchange,
            kind: filterExchange,
            title: `Exchange: ${exchanges[data.data]}`,
            exchange: data.data,
          });
        } else {
          resolve(null);
        }
      })

      await modal.present();
    })();
  });
}

async function showFilterIndustry(value) {
  return new Promise((resolve) => {
    (async () => {

      const modal = await modalController
          .create({
            component: Filter,
            componentProps: {
              input: value,
              title: 'Include selected industry',
              items: industries,
            }
          });

      modal.onDidDismiss().then(data => {
        if (data.data !== undefined) {
          resolve({
            color: "success",
            type: typeFilter,
            icon: iconIndustry,
            kind: filterIndustry,
            title: `Industry: ${industries[data.data]}`,
            industry: data.data,
          });
        } else {
          resolve(null);
        }
      })

      await modal.present();
    })();
  });
}

async function showFilterSector(value) {
  return new Promise((resolve) => {
    (async () => {

      const modal = await modalController
          .create({
            component: Filter,
            componentProps: {
              input: value,
              title: 'Include selected sector',
              items: sectors,
            }
          });

      modal.onDidDismiss().then(data => {
        if (data.data !== undefined) {
          resolve({
            color: "success",
            type: typeFilter,
            icon: iconSector,
            kind: filterSector,
            title: `Sector: ${sectors[data.data]}`,
            sector: data.data,
          });
        } else {
          resolve(null);
        }
      })

      await modal.present();
    })();
  });
}

async function showFilterType(value) {
  return new Promise((resolve) => {
    (async () => {

      const modal = await modalController
          .create({
            component: Filter,
            componentProps: {
              input: value,
              title: 'Include securities of the selected type',
              items: types,
            }
          });

      modal.onDidDismiss().then(data => {
        if (data.data !== undefined) {
          resolve({
            color: "success",
            type: typeFilter,
            icon: iconType,
            kind: filterType,
            title: `Type: ${types[data.data]}`,
            typ: data.data,
          });
        } else {
          resolve(null);
        }
      })

      await modal.present();
    })();
  });
}

async function showCurrency(value) {
  return new Promise((resolve) => {
    (async () => {

      const modal = await modalController
          .create({
            component: Filter,
            componentProps: {
              input: value,
              title: 'Select the primary currency',
              items: currencies,
            }
          });

      modal.onDidDismiss().then(data => {
        if (data.data !== undefined) {
          resolve(data.data);
        } else {
          resolve(null);
        }
      })

      await modal.present();
    })();
  });
}

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    IonAlert,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonList,
    IonListHeader,
    IonRow,
    IonCol,
    IonIcon,
    IonText,
    IonItemDivider,
    IonButton,
    IonItem,
    IonChip,
    IonLabel,
    IonModal,
    IonSpinner,
    IonFooter,

    ObjDpm,
    Filter,
    ConstraintIssuers,
    ConstraintPin,
    ResultTable,
  },

  data() {
    let items = [];
    let savedTags = localStorage.getItem('tags')
    if (savedTags !== null) {
      items = JSON.parse(savedTags);
    }

    let pinned = items.filter(item => {
      return item.kind === constraintPin;
    });

    let p = [];
    let savedP = localStorage.getItem('portfolios')
    if (savedP !== null) {
      p = JSON.parse(savedP);
    }

    let cur = currencies_rev["USD"];
    let savedCur = localStorage.getItem('currency')
    if (savedCur !== null) {
      cur = parseInt(savedCur);
    }

    return {
      items: items,
      pinned: pinned,
      dbinfo: {etf: 0, stock: 0, fund: 0, trust: 0},
      portfolios: p,
      raw_portfolios: [],
      visiblePortfolio: 0,
      computing: false,
      infeasible: false,
      registry: null,
      wasm: null,
      optimizer: null,
      currency: cur,

      iconMaxDividends,
      iconDpm,
      iconFixedCost,
      iconMinimizeCost,
      iconCurrency,
      iconMaximizeDpm,
      iconIndustry,
      iconSector,
      iconInfo,
      iconCountry,
      iconCalculate,
      iconReset,
      iconClose,
      iconEdit,
      iconConstraints,
      iconPin,
      iconExchange,
      iconType,
      iconDelete,
    }
  },

  async mounted() {
    await wasm_bindgen(`/wasm/wealthpic_wasm_bg.wasm`);

    if (this.registry === null) {
      this.registry = all_securities();
    }

    let optimizer = new Worker("/wasm/optimizer.js");
    optimizer.onmessage = (e) => {
      this.raw_portfolios = e.data;
    };

    this.optimizer = optimizer;
  },

  watch: {
    raw_portfolios(portfolios) {
      if (portfolios === null || portfolios.length === 0) {
        this.infeasible = true;

        if (this.pinned.length === 0) {
          this.portfolios = [];
        }
      } else {
        this.infeasible = false;
        this.portfolios = portfolios
            .map(p => {
              p.solutions.forEach(s => {
                s.typ = types[s.typ];

                if (!s.id) {
                  s.id = uuid();
                }
              });

              return p
            });
      }

      this.computing = false;
    },

    registry(val) {
      val.forEach(v => {
        if (v.typ === types_rev["etf"]) {
          this.dbinfo.etf += 1;
        } else if (v.typ === types_rev["stock"]) {
          this.dbinfo.stock += 1;
        } else if (v.typ === types_rev["fund"]) {
          this.dbinfo.fund += 1;
        } else if (v.typ === types_rev["trust"]) {
          this.dbinfo.trust += 1;
        }
      });
    },

    items: {
      handler(val) {
        this.pinned = val.filter(item => {
          return item.kind === constraintPin;
        });

        localStorage.setItem('tags', JSON.stringify(val));
      },
      deep: true,
    },

    portfolios: {
      handler(val) {
        val.forEach(p => {
          let totalVal = 0;
          let totalDpm = 0;

          p.solutions.forEach(s => {
            let val = (s.price * s.count);
            s.value = val;

            totalVal += val;
            totalDpm += s.dpm;
          });

          p.solutions.forEach(s => {
            s.percent = s.value / totalVal * 100;
          });

          p.amount = totalDpm;
        });

        localStorage.setItem('portfolios', JSON.stringify(val));
      },
      deep: true,
    },

    pinned: {
      handler(val) {
        if (this.portfolios.length === 0) {
          this.portfolios.push({solutions: [], amount: 0});
        }

        let portfolioMap = {};
        let idMap = {};
        let portfolio = this.portfolios[this.visiblePortfolio];

        for (let i = 0; i < portfolio.solutions.length; i++) {
          let s = portfolio.solutions[i];
          portfolioMap[s.id] = s;
          idMap[s.id] = i;
        }

        let pinnedMap = {};

        // New ones
        val.forEach(item => {
          pinnedMap[item.id] = true;

          if (!(item.id in portfolioMap)) {
            portfolio.solutions.push({
              id: item.id,
              currency: 0,
              name: item.name,
              ticker: item.ticker,
              dpm: item.dpm,
              price: item.price,
              value: item.price * item.count,
              count: item.count,
              percent: 0,
              typ: types[item.typ],
              pinned: true,
            })
          } else {
            portfolio.solutions[idMap[item.id]].pinned = true;
          }

          // Check if corresponding solution entry needs to be updated
          let sol = this.portfolios[this.visiblePortfolio].solutions
              .find(s => s.id === item.id);

          if (sol) {
            ["name", "ticker", "dpm", "price", "count", "typ"].forEach(k => {
              if (sol[k] !== item[k]) {
                sol[k] = item[k];
              }
            });
          }
        });

        // Update unpinned ones
        portfolio.solutions.forEach(item => {
          if (!(item.id in pinnedMap)) {
            item.pinned = false;
          }
        });
      },
      deep: true,
    },

    currency(val) {
      localStorage.setItem('currency', `${val}`);
    }
  },

  computed: {
    currency_str() {
      return currencies[this.currency];
    },

    hasSolutions() {
      return this.portfolios.length > 0 && this.portfolios[0].solutions.length > 0;
    },

    hasItems() {
      return this.computing || this.items.length > 0
    },

    primaryObjSelected() {
      return this.items.find(el => el.obj_primary) !== undefined;
    },

    calculationDisabled() {
      return this.computing || !this.primaryObjSelected
    },

    dpmSelected() {
      return this.items.find(el => el.kind === obj_dpm) !== undefined;
    },

    fixedCostSelected() {
      return this.items.find(el => el.kind === obj_fixed_cost) !== undefined;
    },

    minimizeCostSelected() {
      return this.items.find(el => el.kind === obj_minimize_cost) !== undefined;
    },

    maximizeDpmSelected() {
      return this.items.find(el => el.kind === obj_maximize_dpm) !== undefined;
    },

    canSelectDpm() {
      return !this.dpmSelected && !this.primaryObjSelected
    },

    canSelectFixedCost() {
      return !this.fixedCostSelected && !this.primaryObjSelected
    },

    canSelectMinimizeCost() {
      return !this.minimizeCostSelected && this.dpmSelected
    },

    canSelectMaximizeDpm() {
      return !this.maximizeDpmSelected && this.fixedCostSelected
    }
  },

  methods: {
    fmt(val) {
      return numeral(val).format('$0,0.00');
    },

    async calculate() {
      let objDpm = null;
      let objFixedCost = null;
      let objMinimizeCost = null;
      let objMaximizeDpm = null;
      let issuer_constraints = [];
      let pin_constraints = [];
      let country_filters = [];
      let exchange_filters = [];
      let industry_filters = [];
      let sector_filters = [];
      let type_filters = [];

      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];

        if (item.kind === obj_dpm) {
          objDpm = [item.value, item.iterations, item.toleration];
        } else if (item.kind === obj_fixed_cost) {
          objFixedCost = [item.value, item.iterations, item.toleration];
        } else if (item.kind === obj_minimize_cost) {
          objMinimizeCost = {};
        } else if (item.kind === obj_maximize_dpm) {
          objMaximizeDpm = {};
        } else if (item.kind === constraintIssuers) {
          let min = item.min === undefined ? item.min : parseInt(item.min, 10);
          let max = item.max === undefined ? item.max : parseInt(item.max, 10);

          issuer_constraints.push({
            min: min,
            max: max,
          });
        } else if (item.kind === constraintPin) {
          pin_constraints.push({
            ticker: item.ticker,
            name: item.name,
            typ: types_rev[item.typ],
            price: item.price,
            dpm: item.dpm,
            count: item.count,
            id: item.id,
          });
        } else if (item.kind === filterCountry) {
          country_filters.push({
            country: item.country,
          });
        } else if (item.kind === filterExchange) {
          exchange_filters.push({
            exchange: item.exchange,
          });
        } else if (item.kind === filterIndustry) {
          industry_filters.push({
            industry: item.industry,
          });
        } else if (item.kind === filterSector) {
          sector_filters.push({
            sector: item.sector,
          });
        } else if (item.kind === filterType) {
          type_filters.push({
            typ: item.typ,
          });
        }
      }

      this.computing = true;

      this.optimizer.postMessage(
          [
            this.currency,
            objDpm,
            objFixedCost,
            objMinimizeCost,
            objMaximizeDpm,
            issuer_constraints,
            pin_constraints,
            country_filters,
            exchange_filters,
            industry_filters,
            sector_filters,
            type_filters
          ]
      );
    },

    reset() {
      this.items.splice(0, this.items.length);
      this.portfolios.splice(0, this.portfolios.length);
    },

    async addObjDpm() {
      let res = await showObjDpm(this.currency_str);
      if (res) {
        this.items.push(res);
      }
    },

    async addObjFixedCost() {
      let res = await showObjFixedCost();
      if (res) {
        this.items.push(res);
      }
    },

    async addObjMinimizeCost() {
      let tag = {
        color: "secondary",
        type: typeObjective,
        icon: iconMinimizeCost,
        kind: obj_minimize_cost,
        title: `Minimize portfolio cost`,
        no_edit: true,
      };

      this.items.push(tag);
    },

    async addObjMaximizeDpm() {
      let tag = {
        color: "secondary",
        type: typeObjective,
        icon: iconMaximizeDpm,
        kind: obj_maximize_dpm,
        title: `Maximize monthly income`,
        no_edit: true,
      };

      this.items.push(tag);
    },

    async addConstraintIssuers() {
      let res = await showConstraintIssuers();
      if (res) {
        this.items.push(res);
      }
    },

    async addConstraintPin() {
      let res = await showConstraintPin(this.registry);
      if (res) {
        this.items.push(res);
      }
    },

    async addFilterCountry() {
      let res = await showFilterCountry();
      if (res) {
        this.items.push(res);
      }
    },

    async addFilterExchange() {
      let res = await showFilterExchange();
      if (res) {
        this.items.push(res);
      }
    },

    async addFilterIndustry() {
      let res = await showFilterIndustry();
      if (res) {
        this.items.push(res);
      }
    },

    async addFilterSector() {
      let res = await showFilterSector();
      if (res) {
        this.items.push(res);
      }
    },

    async addFilterType() {
      let res = await showFilterType();
      if (res) {
        this.items.push(res);
      }
    },

    async removeItem(idx) {
      this.items.splice(idx, 1);
    },

    removePortfolio(idx) {
      this.portfolios.splice(idx, 1);
    },

    async editCurrency() {
      let res = await showCurrency(this.currency);
      if (res !== null) {
        this.currency = res;
      }
    },

    async editItem(idx) {
      let item = this.items[idx];
      let res = undefined;

      if (item.kind === obj_dpm) {
        res = await showObjDpm(this.currency_str, item.value, item.iterations,
            item.toleration);
      } else  if (item.kind === obj_fixed_cost) {
          res = await showObjFixedCost(item.value, item.iterations,
              item.toleration);

      } else if (item.kind === constraintIssuers) {
        res = await showConstraintIssuers(item.min, item.max);
      } else if (item.kind === constraintPin) {
        res = await showConstraintPin(
            this.registry,
            item.ticker, item.name, item.typ, item.price, item.dpm, item.count,
            item.id, item.currency);
      } else if (item.kind === filterCountry) {
        res = await showFilterCountry(item.country);
      } else if (item.kind === filterExchange) {
        res = await showFilterExchange(item.exchange);
      } else if (item.kind === filterIndustry) {
        res = await showFilterIndustry(item.industry);
      } else if (item.kind === filterSector) {
        res = await showFilterSector(item.sector);
      } else if (item.kind === filterType) {
        res = await showFilterType(item.typ);
      }

      if (res) {
        this.items[idx] = res;
      }
    },

    portfolioChange(id) {
      this.visiblePortfolio = id;
    },

    async dpmInfo() {
      const alert = await alertController.create({
        header: 'Fixed monthly income',
        message: 'This objective generates a portfolio of securities paying, on average, the requested amount of US dollars per month as dividends',
        buttons: ['Close']
      });

      await alert.present();
    },

    async fixedCostInfo() {
      const alert = await alertController.create({
        header: 'Fixed portfolio cost',
        message: 'This objective generates a portfolio with a total requested cost.',
        buttons: ['Close']
      });

      await alert.present();
    },

    async minimizePortfolioCostInfo() {
      await info('Minimize portfolio cost',
          "By default, the generated portfolio is completely random, as is the total portfolio cost. If you'd like to try finding a portfolio with the smallest possible total cost, add this objective.");
    },

    async maximizeDpmInfo() {
      await info('Maximize monthly income',
          "By default, the generated portfolio is completely random, as is the monthly income. If you'd like to try finding a portfolio with the maximum possible monthly income, add this objective.");
    },

    async constraintsIssuersInfo() {
      await info('Issuers constraint',
          'By default, the generated portfolio can contain a wide range of issuers. This constraint allows you to tweak the minimum/maximum number of those.');
    },

    async constraintsPinInfo() {
      await info('Pinned entry constraint',
          'By default, the generated portfolio can contain a wide range of issuers. This constraint allows you to tweak the minimum/maximum number of those.');
    },

    async countryFilterInfo() {
      await info('Filter by country',
          'Only include securities from the selected country. When multiple country filters are added, the final result will contain securities from all of them.');
    },

    async currencyFilterInfo() {
      await info('Filter by currency',
          'Only include securities with the selected currency. When multiple currency filters are added, the final result will contain securities with all of them.');
    },

    async exchangeFilterInfo() {
      await info('Filter by stock exchange',
          'Only include securities traded on the selected exchange. When multiple exchange filters are added, the final result will contain securities from all of them.');
    },

    async industryFilterInfo() {
      await info('Filter by industry',
          'Only include securities from the selected industry. When multiple industry filters are added, the final result will contain securities from all of them. Please note, that for ETFs industry is not defined.');
    },

    async sectorFilterInfo() {
      await info('Filter by sector',
          'Only include securities from the selected sector. When multiple sector filters are added, the final result will contain securities from all of them. Please note, that for ETFs sector is not defined.');
    },

    async typeFilterInfo() {
      await info('Filter by security type',
          'Only include securities of a selected type. When multiple type filters are added, the final result will contain securities of all of them.');
    },

    onPinned(id) {
      let sol = this.portfolios[this.visiblePortfolio].solutions.find(
          s => s.id === id);
      let title = `Pin: ${sol.ticker} shares=${sol.count} price=${fmtMoney(
          sol.price)} ${currencies[sol.currency]}`;

      this.items.push({
        color: "warning",
        type: typeConstraint,
        icon: iconPin,
        kind: constraintPin,
        title: title,
        name: sol.name,
        ticker: sol.ticker,
        typ: sol.typ,
        price: sol.price,
        dpm: sol.dpm,
        count: sol.count,
        currency: sol.currency,
        id: id,
      });
    },

    onUnpinned(id) {
      let sol = this.portfolios[this.visiblePortfolio].solutions.find(
          s => s.id === id);

      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];

        if (item.id === sol.id) {
          this.items.splice(i, 1);
          return;
        }
      }
    },

    onDropped(idx) {
      this.onUnpinned(idx);
      this.portfolios[this.visiblePortfolio].solutions.splice(idx, 1);
    }
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  flex-grow: 1;
}

.footer {
  flex-shrink: 0;
}

#container {
  text-align: center;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

.howto {
  font-size: medium;
}

.edit {
  margin-left: 10px;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  margin-inline: 10px;
}

.tab-title {
  display: inline-block;
  padding: 5px;
  color: var(--ion-color-primary);
  font-weight: bold;
  font-size: large;
  cursor: pointer;
  margin-right: 10px;
}

.tab-title ion-icon {
  padding-left: 5px;
  cursor: pointer;
}

.tab-selected {
  color: var(--ion-color-primary);
  border-bottom: 1px solid;
}

.footer {
  flex-shrink: 0;
}

</style>