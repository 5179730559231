<template>
  <ion-page>
    <Header></Header>

    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-xs="10" size-md="9" size-lg="6">

            <ion-card class="ion-padding">
              <p>If you're looking into having a reliable passive income source,
                it might be challenging to come up with the right stock/ETF portfolio
                that generate the required amount of monthly income.</p>

              <p>With the help of advanced genetic algorithms, WealthPIC can
                automatically generate the right portfolio for you.</p>

              <p>There are many objectives, constraints, and filters available
                that allow one to tweak and fine-tune portfolios
                according to required criteria.
              </p>

              <ion-item-divider></ion-item-divider>

              Got a comment or a question?<br />
              <a href="mailto:wealthpic@syhpoon.ca">
                Drop us a line!
              </a>
            </ion-card>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <Footer></Footer>
  </ion-page>
</template>

<script>

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';

import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'About',
  components: {
    Header,
    Footer,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
  },
};
</script>
