<template>
  <ion-header :translucent="true">
    <ion-toolbar>

      <ion-title>
        <img src="../../public/assets/icon/logo-110x40.png" class="logo" alt="logo"
        width="110" height="40" />
        <router-link class="link" to="/">Free Passive Income Calculator</router-link>
      </ion-title>

      <span slot="end">
        <router-link to="/disclaimer" class="right link">Disclaimer</router-link>
        <!--<router-link to="/help" class="right link">Help</router-link>-->
        <router-link to="/about" class="right link">About</router-link>
      </span>
    </ion-toolbar>
  </ion-header>
</template>

<script>

import {
  IonHeader,
  IonToolbar,
  IonTitle,
} from '@ionic/vue';

export default {
  name: 'Header',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
  },
};
</script>

<style scoped>

.right {
  padding-right: 10px;
}

ion-toolbar a {
  color: var(--ion-color-dark)
}

.logo {
  margin-left: 5px;
  margin-right: 10px;
}

</style>
