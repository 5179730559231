<template>
  <ion-page>
    <Header></Header>

    <ion-content :fullscreen="true">
      <ion-grid class="help">
        <ion-row>
          <ion-col size="12" size-lg="4">

            <ion-card class="ion-padding">
              <ion-list>
                <ion-item>
                  <a href="/help/#introduction">Introduction?</a>
                </ion-item>

                <ion-item>
                  <a href="/help/#what-is-objective">What is an objective?</a>
                </ion-item>

                <ion-item>Objective: Fixed monthly income</ion-item>
                <ion-item>What is constraint?</ion-item>
                <ion-item>Constraint: Issuers</ion-item>
                <ion-item>Constraint: Pin</ion-item>
                <ion-item>What is filter?</ion-item>
                <ion-item>Filter: Country</ion-item>
                <ion-item>Filter: Exchange</ion-item>
                <ion-item>Filter: Industry</ion-item>
                <ion-item>Filter: Type</ion-item>

                <ion-item>
                  <a href="/help/#how-to-use-wealthpic">How to use WealthPIC</a>
                </ion-item>
              </ion-list>
            </ion-card>

          </ion-col>

          <ion-col>
            <ion-card class="ion-padding" id="introduction">
              <ion-card-header>
                <ion-card-title>Introduction</ion-card-title>
              </ion-card-header>

              <p>Welcome to WealthPIC!</p>
              <br />
              <p>
                WealthPIC is a tool that allows you to automatically
                generate stock and ETF portfolios for a given goal.
              </p>
              <p>
                Why would you need this? Well, the main focus of WealthPIC
                is passive dividend income. Many people dream about having
                a stream of steady income which wouldn't depend on hours
                spent working for somebody. Dividend-paying stocks and ETFs
                is one way to achieve this.
              </p>

              <br />

              <p>
                A lot of talks about passive income start with phrases like
                <i>If I received 5k a month, I'd be able to...</i>.
                The challenging part is, however, to assemble a portfolio
                that would yield this required amount.
              </p>

              <p>
                The number of dividend-paying stocks and ETFs is huge and the
                number of all possible combinations that can generate the required
                amount of monthly income is truly astronomical.
              </p>

              <p>
                However, there are techniques out there that allow one
                to efficiently navigate this mind-blowing search space
                and discover just the right combinations of stocks and shares.
              </p>

              <p>
                In particular, WealthPIC uses advanced
                <a href="https://en.wikipedia.org/wiki/Genetic_algorithm">
                  Genetic algorithms
                </a> to quickly and accurately find
                just the right portfolio for you.
              </p>
            </ion-card>

            <ion-card class="ion-padding" id="what-is-objective">
              <ion-card-header>
                <ion-card-title>What is an objective?</ion-card-title>
              </ion-card-header>

              <p>
                Simply speaking, objective is a goal you want to achieve.
                Each goal can have a set of mandatory and optional parameters
                with which you can better express your desired outcome.
              </p>

              <p>
                Algorithms used in WealthPIC will take the parameters you
                specified
                and try to come up with the best possible answer.
              </p>

              <br />

              <p>
                Currently there's only one objective available in WealthPIC -
                <strong>"Fixed monthly income"</strong>, but there may be
                more implemented in the future.
              </p>
            </ion-card>

            <ion-card class="ion-padding" id="fixed-monthly-income">
              <ion-card-header>
                <ion-card-title>Objective: Fixed monthly income</ion-card-title>
              </ion-card-header>

              <p>
                This objective tries to find a portfolio that would pay you
                a required amount of dollars each month.
              </p>

            </ion-card>

            <ion-card class="ion-padding">
              <ion-card-header>
                <ion-card-title>What is constraint?</ion-card-title>
              </ion-card-header>

              <p>
                If you're looking into having a reliable passive income source,
                it might be challenging to come up with the right stock/ETF
                portfolio
                that generate the required amount of monthly income.
              </p>
            </ion-card>

            <ion-card class="ion-padding">
              <ion-card-header>
                <ion-card-title>What is filter?</ion-card-title>
              </ion-card-header>

              <p>
                If you're looking into having a reliable passive income source,
                it might be challenging to come up with the right stock/ETF
                portfolio
                that generate the required amount of monthly income.
              </p>
            </ion-card>

            <ion-card class="ion-padding" id="how-to-use-wealthpic">
              <ion-card-header>
                <ion-card-title>How to use WealthPIC</ion-card-title>
              </ion-card-header>

              <p>
                There are many ways you could use WealthPIC, the main goal we
                had when developing it was to be able to explore and gradually
                fine-tune the portfolio which would exactly satisfy your financial
                goals.
              </p>

              <p>
                In short, the intended sequences of actions would be the following:
              </p>

              <ol>
                <li>Select the objective and generate a random portfolio</li>
                <li>
                  Pin entries you like (click on the entry and then
                  the <strong>Pin</strong> button).
                  those you'd want to keep in your final portfolio.

                  Those securities you pin will remain in portfolio even when
                  you click the <strong>Calculate</strong> button again.
                </li>
                <li>
                  If there are securities you'd like to exclude completely,
                  you can ban them, by clicking the <strong>Ban</strong> button
                  or using the `Ban` constraint.
                </li>
                <li>Add additional filters/constraints</li>
                <li>Re-calculate the portfolio</li>
              </ol>

              Hopefully, after some iterations of this process you'll end up
              with a portfolio you like.
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <Footer></Footer>
  </ion-page>
</template>

<script>

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';

import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'Help',
  components: {
    Header,
    Footer,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonCardSubtitle,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonGrid,
    IonRow,
    IonCol,
  },
};
</script>

<style scoped>
.help p {
  margin-block-start: 2px;
  margin-block-end: 2px;
}

</style>
