<template>
  <div class="ion-padding">
    <ion-text color="primary">
      Optimize for the total portfolio cost
    </ion-text>

    <ion-item lines="full">
      <ion-label position="floating">Value</ion-label>
      <ion-input type="number" v-model="dataValue" min="1" required></ion-input>
      <ion-icon :icon="iconInfo" slot="end" @click.stop="valueInfo"></ion-icon>
    </ion-item>

    <ion-item-divider>
      <ion-label>Advanced settings</ion-label>
    </ion-item-divider>

    <ion-item lines="full">
      <ion-label position="floating">Max iterations</ion-label>
      <ion-input type="number" v-model="dataIterations" min="1"
                 required></ion-input>
      <ion-icon :icon="iconInfo" slot="end" @click.stop="maxIterInfo"></ion-icon>
    </ion-item>

    <ion-item lines="full">
      <ion-label position="floating">Toleration</ion-label>
      <ion-input type="number" v-model="dataToleration" min="0"
                 required></ion-input>
      <ion-icon :icon="iconInfo" slot="end" @click.stop="tolerationInfo"></ion-icon>
    </ion-item>

    <div class="ion-margin">
      <ion-button color="success" @click="save()">Save</ion-button>
      <ion-button color="danger" @click="cancel()">Cancel</ion-button>
    </div>

  </div>
</template>

<script>
import {
  informationCircle as iconInfo,
} from 'ionicons/icons';

import {
  IonItem,
  IonItemDivider,
  IonLabel,
  IonText,
  IonNote,
  IonInput,
  IonButton,
  IonIcon,

  modalController,
} from '@ionic/vue';

import {info} from "@/lib";

export default {
  name: "ObjFixedCost",
  components: {
    IonItem,
    IonItemDivider,
    IonLabel,
    IonText,
    IonNote,
    IonInput,
    IonButton,
    IonIcon,
  },

  props: {
    value: {
      type: Number,
      default: 1000,
    },

    iterations: {
      type: Number,
      default: 10000,
    },

    toleration: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      dataValue: this.value,
      dataIterations: this.iterations,
      dataToleration: this.toleration,
      iconInfo,
    }
  },

  methods: {
    save() {
      modalController.dismiss({
        value: this.dataValue,
        iterations: parseInt(this.dataIterations, 10),
        toleration: parseInt(this.dataToleration, 10),
      });
    },

    cancel() {
      modalController.dismiss();
    },

    async valueInfo() {
      await info('Value',
          "The value specifies the total cost of the generated portfolio in the selected currency. I.e. if you'd like to purchase a dividend-yielding portfolio for $10000 +- $10, you'd input 10000 for 'Value' and '10' for 'Toleration' below");
    },

    async maxIterInfo() {
      await info('Maximum number of iterations',
          "The generation process is done in many steps (iterations). This knob allows you to change the maximum amount of iterations you'd want to wait for the solution. Higher number can yield portfolios with a better precision but it can take longer time and consume more CPU.");
    },

    async tolerationInfo() {
      await info('Toleration',
          "This value specifies the acceptable toleration level for the portfolio solution. For example, if you selected '$1000' as a value above, and toleration is '10', the optimization will terminate successfully when a portfolio cost reaches 1000+-10 -- 990 to 1010 range.");
    },
  }
}
</script>