const COUNTRY_CODES = {
    "US": "USA",
    "CA": "Canada",
    "BR": "Brasil",
    "CN": "China",
    "DE": "Germany",
    "GB": "UK",
    "IN": "India",
    "NL": "The Netherlands",
    "RU": "Russia",
};

export default COUNTRY_CODES;