<template>
  <ion-content>
    <div class="ion-padding">
      <ion-text color="primary">
        Pin security
      </ion-text>

      <ion-item lines="full">
        <ion-label position="floating">Ticker</ion-label>
        <ion-input v-model="dataTicker" min="1"
                   @focusout="onTickerBlur"></ion-input>
        <ion-icon :icon="iconInfo" slot="end"
                  @click.stop="tickerInfo"></ion-icon>
      </ion-item>

      <ion-item lines="full">
        <ion-label position="floating">Name</ion-label>
        <ion-input v-model="dataName" min="1"></ion-input>
        <ion-icon :icon="iconInfo" slot="end"
                  @click.stop="nameInfo"></ion-icon>
      </ion-item>

      <ion-item lines="full">
        <ion-label position="floating">Price</ion-label>
        <ion-input v-model="dataPrice" min="1"></ion-input>
        <ion-icon :icon="iconInfo" slot="end"
                  @click.stop="priceInfo"></ion-icon>
      </ion-item>

      <ion-item lines="full">
        <ion-label position="floating">Shares</ion-label>
        <ion-input type="number" v-model="dataCount" min="1"></ion-input>
        <ion-icon :icon="iconInfo" slot="end"
                  @click.stop="countInfo"></ion-icon>
      </ion-item>

      <ion-item lines="full">
        <ion-label position="floating">Total dividends paid per month
        </ion-label>
        <ion-input type="number" v-model="dataDpm" min="1"></ion-input>
        <ion-icon :icon="iconInfo" slot="end"
                  @click.stop="dpmInfo"></ion-icon>
      </ion-item>

      <ion-radio-group v-model="dataType">
        <ion-list-header>
          <ion-label>Type</ion-label>
        </ion-list-header>

        <ion-item>
          <ion-label>ETF</ion-label>
          <ion-radio value="ETF"></ion-radio>
        </ion-item>

        <ion-item>
          <ion-label>Stock</ion-label>
          <ion-radio value="Stock"></ion-radio>
        </ion-item>
      </ion-radio-group>

      <ion-radio-group v-model="dataCurrency">
        <ion-list-header>
          <ion-label>Currency</ion-label>
        </ion-list-header>

        <ion-item v-for="(v, k) in currencies">
          <ion-label>{{ v }}</ion-label>
          <ion-radio :value="k"></ion-radio>
        </ion-item>

      </ion-radio-group>

      <div class="ion-margin">
        <ion-button color="success" @click="save()">Save</ion-button>
        <ion-button color="danger" @click="cancel()">Cancel</ion-button>
      </div>

    </div>
  </ion-content>
</template>

<script>
import {
  informationCircle as iconInfo,
} from 'ionicons/icons';

import {
  IonItem,
  IonLabel,
  IonText,
  IonInput,
  IonButton,
  IonIcon,
  IonRadio,
  IonRadioGroup,

  modalController,
} from '@ionic/vue';

import {info} from "@/lib";
import {types, currencies} from "@/data";

export default {
  name: "ConstraintPinned",
  components: {
    IonItem,
    IonLabel,
    IonText,
    IonInput,
    IonButton,
    IonIcon,
    IonRadio,
    IonRadioGroup,
  },

  props: {
    ticker: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    typ: {
      type: String,
      default: '',
    },
    currency: {
      type: Number,
      default: 0,
    },
    price: {
      type: Number,
      default: 0,
    },
    dpm: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
    registry: {
      default: [],
    },
  },

  data() {
    return {
      dataTicker: this.ticker,
      dataName: this.name,
      dataType: this.typ,
      dataPrice: this.price,
      dataDpm: this.dpm,
      dataCount: this.count,
      dataCurrency: this.currency,
      iconInfo,
      currencies,
    }
  },

  watch: {
    dataCount(val) {
      if (this.dataTicker) {
        let info = this.registry.find(i => i.ticker === this.dataTicker);

        if (info) {
          this.dataDpm = (val * info.dps).toFixed(2);
        }
      }
    },
  },

  methods: {
    save() {
      modalController.dismiss({
        ticker: this.dataTicker,
        name: this.dataName,
        typ: this.dataType,
        price: parseInt(this.dataPrice),
        dpm: parseInt(this.dataDpm),
        count: parseInt(this.dataCount),
        currency: parseInt(this.dataCurrency)
      });
    },

    cancel() {
      modalController.dismiss();
    },

    onTickerBlur() {
      let info = this.registry.find(i => i.ticker === this.dataTicker);
      if (info) {
        this.dataName = info.name;
        this.dataPrice = info.price.toString();
        this.dataDpm = info.dps.toString();
        this.dataType = types[info.typ];
      }
    },

    async tickerInfo() {
      await info('Security ticker', "This name of the security ticker.");
    },

    async nameInfo() {
      await info('Security name', "Stock/ETF name.");
    },

    async priceInfo() {
      await info('Share price', "Price of a single share.");
    },

    async dpmInfo() {
      await info('Dividends per month',
          "Total dividends paid per month for the provided number of shares.");
    },

    async countInfo() {
      await info('Number of shares',
          "Total number of shares in this record.");
    },
  }
}
</script>