import {fmtMoney} from '@/lib';

export default {
  methods: {
    fmtMoney(val) {
      return fmtMoney(val);
    },

    fmtFloat(val) {
      return val.toFixed(2);
    },
  }
};
