<template>
  <ion-page>
    <Header></Header>

    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-xs="10" size-md="9" size-lg="6">

            <ion-card class="ion-padding">
              <p>
                All data and information is provided “as is” for informational
                purposes only, and is not intended for trading purposes or
                financial, investment, tax, legal, accounting or other advice.
                Please consult your broker or financial representative to verify
                pricing before executing any trade. Wealthpic is not an
                investment adviser, financial adviser or a securities broker.
                None of the data and information constitutes investment advice
                nor an offering, recommendation or solicitation by Wealthpic to
                buy, sell or hold any security or financial product, and
                Wealthpic makes no representation (and has no opinion) regarding
                the advisability or suitability of any investment.
              </p>

              <p>
                None of the data and information constitutes investment advice
                (whether general or customized). The financial products or
                operations referred to in such data and information may not be
                suitable for your investment profile and investment objectives
                or expectations. It is your responsibility to consider whether
                any financial product or operation is suitable for you based on
                your interests, investment objectives, investment horizon and
                risk appetite. Wealthpic shall not be liable for any damages
                arising from any operations or investments in financial products
                referred to within. Wealthpic does not recommend using the data
                and information provided as the only basis for making any
                investment decision.
              </p>

              <p>
                Data is provided by financial exchanges and other content
                providers and may be delayed as specified by financial exchanges
                or other data providers. Wealthpic does not verify any data and
                disclaims any obligation to do so.
              </p>

              <p>
                Wealthpic expressly disclaims the accuracy, adequacy,
                or completeness of any data and
                shall not be liable for any errors, omissions or other
                defects in, delays or interruptions in such data, or for any
                actions taken in reliance thereon. Neither Wealthpic nor any of our
                information providers will be liable for any damages relating to
                your use of the information provided herein.
              </p>
            </ion-card>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <Footer></Footer>
  </ion-page>
</template>

<script>

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';

import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'Disclaimer',
  components: {
    Header,
    Footer,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
  },
};
</script>
