import { createRouter, createWebHistory } from '@ionic/vue-router';
import Home from '../components/Home.vue'
import About from '../components/About.vue';
import Disclaimer from '../components/Disclaimer.vue';
import Help from '../components/Help.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
