<template>
  <div class="ion-padding">
    <ion-text color="primary">
      Constraint the amount of issuers
    </ion-text>

    <ion-item lines="full">
      <ion-label position="floating">Minimum number of issuers</ion-label>
      <ion-input type="number" v-model="dataMin" min="1"></ion-input>
      <ion-icon :icon="iconInfo" slot="end"
                @click.stop="minInfo"></ion-icon>
    </ion-item>

    <ion-item lines="full">
      <ion-label position="floating">Maximum number of issuers</ion-label>
      <ion-input type="number" v-model="dataMax" min="1"></ion-input>
      <ion-icon :icon="iconInfo" slot="end"
                @click.stop="maxInfo"></ion-icon>
    </ion-item>

    <div class="ion-margin">
      <ion-button color="success" @click="save()">Save</ion-button>
      <ion-button color="danger" @click="cancel()">Cancel</ion-button>
    </div>

  </div>
</template>

<script>
import {
  informationCircle as iconInfo,
} from 'ionicons/icons';

import {
  IonItem,
  IonLabel,
  IonText,
  IonInput,
  IonButton,
  IonIcon,

  modalController,
} from '@ionic/vue';

import {info} from "@/lib";

export default {
  name: "ConstraintIssuers",
  components: {
    IonItem,
    IonLabel,
    IonText,
    IonInput,
    IonButton,
    IonIcon,
  },

  props: {
    min: {
      type: Number,
      default: '',
    },
    max: {
      type: Number,
      default: '',
    },
  },

  data() {
    return {
      dataMin: this.min,
      dataMax: this.max,
      iconInfo,
    }
  },

  methods: {
    save() {
      let min = this.dataMin.length === 0 ? undefined: this.dataMin;
      let max = this.dataMax.length === 0 ? undefined: this.dataMax;

      modalController.dismiss({min, max});
    },

    cancel() {
      modalController.dismiss();
    },

    async minInfo() {
      await info('Minimum number of issuers',
          "This value specifies the minimum number of issuers to include in the portfolio.");
    },

    async maxInfo() {
      await info('Maximum number of issuers',
          "This value specifies the maximum number of issuers to include in the portfolio.");
    },
  }
}
</script>